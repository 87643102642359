<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <body-card>
          <template>
            <b-overlay :show="load" v-if="formData.org_id">
              <b-row>
                <b-col>
                  <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="formData.org_id">
                    <div class="titleDiv">
                      <span class="text-center text-dark reportTitle" style="font-weight:bold;">{{ $t('elearning_tpm.training_schedule') + ' ' + $t('globalTrans.details') }}</span><br>
                    </div>
                  </list-report-head>
                </b-col>
              </b-row>
              <b-col lg="12" sm="12">
                <b-row>
                  <b-col lg="12" sm="12" class="p-0">
                    <b-table-simple bordered>
                      <b-tr>
                        <b-th style="width:35%;" scope="row" class="pt-2 pb-2 text-left bg-light">{{ $t('elearning_config.fiscal_year') }}</b-th>
                        <b-td style="width:65%%;" class="pt-2 pb-2 text-left bg-light">{{ $i18n.locale === 'bn' ? formData.fiscal_year_bn : formData.fiscal_year }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th scope="row" class="pt-2 pb-2 text-left bg-light">{{ $t('elearning_config.organization') }}</b-th>
                        <b-td  class="pt-2 pb-2 text-left bg-light">{{ $i18n.locale === 'bn' ? formData.org_bn : formData.org }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th style="width:35%;" scope="row" class="pt-2 pb-2 text-left bg-light">{{ $t('externalTraining.circular_memo_no') }}</b-th>
                        <b-td style="width:65%%;" class="pt-2 pb-2 text-left bg-light">{{ formData.circular_memo_no }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th scope="row" class="pt-2 pb-2 text-left bg-light">{{ $t('elearning_config.training_type') }}</b-th>
                        <b-td  class="pt-2 pb-2 text-left bg-light">{{ $i18n.locale === 'bn' ? formData.training_type_bn : formData.training_type }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th scope="row" class="pt-2 pb-2 text-left bg-light">{{ $t('elearning_config.training_title') }}</b-th>
                        <b-td  class="pt-2 pb-2 text-left bg-light">{{ $i18n.locale === 'bn' ? formData.training_title_bn : formData.training_title }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th scope="row" class="pt-2 pb-2 text-left bg-light">{{ $t('elearning_config.batch') }}</b-th>
                        <b-td  class="pt-2 pb-2 text-left bg-light">{{ $i18n.locale === 'bn' ? formData.batch_bn : formData.batch }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th scope="row" class="pt-2 pb-2 text-left bg-light">{{ $t('globalTrans.date') }}</b-th>
                        <b-td  class="pt-2 pb-2 text-left bg-light">{{ date }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th scope="row" class="pt-2 pb-2 text-left bg-light">{{ $t('elearning_tpm.training_fee') }}</b-th>
                        <b-td  class="pt-2 pb-2 text-left bg-light">{{ $n(training_fee) }}</b-td>
                      </b-tr>
                    </b-table-simple>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="12" sm="12">
                    <!-- <b-col sm="12" style="background-color: var(--gray-ddd);" class="p-2 mb-2">
                      <template>
                        <h6 style="" class="text-center">{{ $t('elearning_tpm.training_schedule') }}</h6>
                      </template>
                    </b-col> -->
                      <h6 class="text-center text-dark reportTitle mb-1 mt-2" style="font-weight:bold;">{{ $t('elearning_tpm.training_schedule') }}</h6>
                    <slot v-if="routines.length>0">
                      <div v-for="(routine,index) in routines" :key="index">
                        <b-row class="pl-2 pr-2">
                          <b-col lg="6" sm="6" class="text-left pl-10">
                            <b>{{ $t('globalTrans.day') }}-{{$n(index+1)}}</b>
                          </b-col>
                          <b-col lg="6" sm="6"  class="text-right pr-10">
                            <b>{{routine.date | dateFormat}} ({{dayGetName(routine.date)}})</b>
                          </b-col>
                          <b-table-simple bordered>
                            <b-tr class="bg-primary text-white">
                              <b-th style="width:30%" class="pt-2 pb-2 text-center">{{ $t('globalTrans.time') }}</b-th>
                              <b-th style="width:25%" class="pt-2 pb-2 text-center">{{$t('elearning_config.topics_session')}}</b-th>
                              <b-th style="width:20%" class="pt-2 pb-2 text-center">{{$t('elearning_config.speaker_facilitator')}}</b-th>
                              <b-th style="width:25%" class="pt-2 pb-2 text-center">{{$t('elearning_config.vanue_name')}}</b-th>
                            </b-tr>
                            <template v-for="sheduleData in routine.time">
                              <b-tr v-for="(schedule,index1) in sheduleData.list" :key="index1">
                                <b-td v-if="index1 == 0" :rowspan="sheduleData.list.length" class="pt-2 pb-2 text-center">
                                  {{schedule.start_time | time12Formate}} - {{schedule.end_time | time12Formate}}
                                </b-td>
                                <b-td v-if="index1 == 0" :rowspan="sheduleData.list.length" >
                                  {{ $i18n.locale === 'bn' ? schedule.topics_session_bn : schedule.topics_session }}
                                </b-td>
                                <b-td class="pt-2 pb-2 text-center">
                                  {{ $i18n.locale === 'bn' ? schedule.speaker_bn : schedule.speaker }}
                                </b-td>
                                <b-td class="pt-2 pb-2 text-center" v-if="index1 == 0" :rowspan="sheduleData.list.length" >
                                  {{ $i18n.locale === 'bn' ? schedule.venue_bn : schedule.venue }}
                                </b-td>
                              </b-tr>
                            </template>
                          </b-table-simple>
                        </b-row>
                      </div>
                    </slot>
                  </b-col>
                </b-row>
                <b-row v-if="committee">
                  <b-col lg="12" sm="12">
                    <!-- <b-col sm="12" style="background-color: var(--gray-ddd);" class="p-2 mb-3">
                      <template>
                        <h6 style="" class="text-center">{{ $t('elearning_tpm.Course_coordinator') }}</h6>
                      </template>
                    </b-col> -->
                    <h6 class="text-center text-dark reportTitle mb-3" style="font-weight:bold;">{{ $t('elearning_tpm.course_coordinator') }}</h6>
                    <b-table-simple bordered>
                      <b-tr class="bg-primary text-white">
                        <b-th style="width:10%" class="pt-2 pb-2 text-center">{{ $t('globalTrans.name') }}</b-th>
                        <b-th style="width:10%" class="pt-2 pb-2 text-center">{{$t('globalTrans.designation')}}</b-th>
                        <b-th style="width:10%" class="pt-2 pb-2 text-center">{{$t('globalTrans.mobile_no')}} / {{ $t('globalTrans.email') }}</b-th>
                      </b-tr>
                      <b-tr v-for="(committee,index2) in committee.details" :key="index2">
                        <b-td class="pt-2 pb-2 text-center">{{ $i18n.locale === 'bn' ? committee.name_bn : committee.name_en }}</b-td>
                        <b-td class="pt-2 pb-2 ">
                          {{ $i18n.locale === 'bn' ? committee.designation_bn : committee.designation_en }}
                        </b-td>
                        <b-td class="pt-2 pb-2 text-center">
                          {{ ($i18n.locale === 'bn' ? '০' : '0') + $n(committee.mobile_no, { useGrouping: false }) }} <br>
                          {{ committee.email }}
                        </b-td>
                      </b-tr>
                    </b-table-simple>
                  </b-col>
                </b-row>
              </b-col>
            </b-overlay>
          </template>
        </body-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<style>
.tables-data th, .tables-data td {
  padding: 0px 0px 0px 10px !important;
}
.tables-data {
  margin-bottom: 0px !important
}
</style>
<script>

import flatpickr from 'flatpickr'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'
import ExportPdf from './export_pdf_details'
import { trainingScheduleRoutine, trainingScheduleCommittee, trainingScheduleCircularData } from '../../api/routes'
import moment from 'moment'

export default {
  components: {
    ListReportHead
  },
  props: ['id'],
  created () {
    if (this.id) {
      const tmp = this.getFormData()
      this.formData = tmp
      this.getCircularWiseData(tmp)
      this.getCircularData(tmp.circular_memo_no)
      this.committeeData(tmp)
    }
  },
  mounted () {
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        name: '',
        email: '',
        mobile: '',
        fiscal_year_id: 0,
        office_type_id: 0,
        office_id: 0,
        org_id: 0,
        room_type_id: 0,
        room_rent_id: 0,
        floor_id: 0,
        room_id: 0
      },
      date: '',
      training_fee: 0,
      officeTypeList: [],
      officeList: [],
      routines: [],
      committee: [],
      load: false,
      dateErrorMsg: '',
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      trainingTitleList: []
    }
  },
  computed: {
  },
  methods: {
    dayGetName (value) {
      return moment(value).format('dddd')
    },
    async getCircularWiseData (search) {
      this.load = true
      const routineSearch = {
        circular_memo_no: search.circular_memo_no,
        batch_id: search.batch_id
      }
      RestApi.getData(trainingElearningServiceBaseUrl, trainingScheduleRoutine, routineSearch).then(response => {
        if (response.success) {
          this.routines = response.data
          this.load = false
        } else {
          this.load = false
        }
      })
    },
    async getCircularData (circularMemoNo) {
      this.load = true
      const params = {
        circular_memo_no: circularMemoNo
      }
      RestApi.getData(trainingElearningServiceBaseUrl, trainingScheduleCircularData, params).then(response => {
        if (response.success) {
          this.training_fee = response.data.registration_fee
          const startDate = response.data.training_start_date.split('-')
          const endDate = response.data.training_end_date.split('-')
          let startMonthId = ''
          let endMonthId = ''
          startMonthId = this.$store.state.commonObj.monthList.find(doc => doc.value === parseInt(startDate[1]))
          endMonthId = this.$store.state.commonObj.monthList.find(doc => doc.value === parseInt(endDate[1]))
          if (startDate[1] === endDate[1]) {
            this.date = this.$n(startDate[2]) + ' - ' + this.$n(endDate[2]) + ' ' + (this.$i18n.locale === 'en' ? startMonthId.text_en : startMonthId.text_bn) + ', ' + this.$n(startDate[0], { useGrouping: false })
          } else {
            this.date = this.$n(startDate[2]) + ' ' + (this.$i18n.locale === 'en' ? startMonthId.text_en : startMonthId.text_bn) + ' - ' + this.$n(endDate[2]) + ' ' + (this.$i18n.locale === 'en' ? endMonthId.text_en : endMonthId.text_bn) + ', ' + this.$n(startDate[0], { useGrouping: false })
          }
          this.load = false
        } else {
          this.load = false
        }
      })
    },
    async committeeData (search) {
      this.load = true
      const commiteeSearch = {
        fiscal_year_id: search.fiscal_year_id,
        org_id: search.org_id
      }
      RestApi.getData(trainingElearningServiceBaseUrl, trainingScheduleCommittee, commiteeSearch).then(response => {
        if (response.success) {
          this.committee = response.data
          this.load = false
        } else {
          this.committee = []
          this.load = false
        }
      })
    },
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    pdfExport () {
      const reportTitle = this.$t('elearning_tpm.training_schedule')
      ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', this.formData.org_id, reportTitle, this.formData, this, this.routines, this.committee)
    }
  }
}
</script>
