import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/utils/report-head-traning'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat, time12FormateTraining } from '@/utils/fliter'

const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, vm, allData) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
      const pdfContent = [{
        columns: reportHeadData.reportHeadColumn
            },
            { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
            { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
            { text: reportHeadData.address, style: 'address', alignment: 'center' },
            { text: reportTitle, style: 'hh', alignment: 'center' }
        ]
        const allRows = [
          [
            { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('elearning_config.organization'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('elearning_config.training_title'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('globalTrans.course'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('elearning_iabm.circular_memo_no'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('elearning_tpm.trainer'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('elearning_tpm.course_module'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('globalTrans.date'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('globalTrans.start_time'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('globalTrans.end_time'), style: 'th', alignment: 'center', bold: true }
          ]
        ]
        allData.forEach((info, index) => {
          allRows.push([
            { text: vm.$n(index + 1), alignment: 'center', style: 'td' },
            { text: (i18n.locale === 'bn') ? info.org_bn : info.org, alignment: 'center', style: 'td' },
            { text: (i18n.locale === 'bn') ? info.training_title_bn : info.training_title, alignment: 'center', style: 'td' },
            { text: (i18n.locale === 'bn') ? info.course_name_bn : info.course_name, alignment: 'center', style: 'td' },
            { text: (i18n.locale === 'bn') ? info.circular_memo_no : info.circular_memo_no, alignment: 'center', style: 'td' },
            { text: (i18n.locale === 'bn') ? info.trainer_name_bn : info.trainer_name, alignment: 'center', style: 'td' },
            { text: (i18n.locale === 'bn') ? info.course_module_name_bn : info.course_module_name, alignment: 'center', style: 'td' },
            { text: dateFormat(info.training_date), alignment: 'center', style: 'td' },
            { text: time12FormateTraining(info.start_time), alignment: 'center', style: 'td' },
            { text: time12FormateTraining(info.end_time), alignment: 'center', style: 'td' }
          ])
        })
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['6%', '17%', '11%', '11%', '10%', '10%', '10%', '9%', '8%', '8%'],
            body: allRows
          }
        })
      const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
      var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'landscape',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: 10,
            margin: [1, 1, 1, 1]
          },
          header: {
              fontSize: 14,
              bold: true,
              alignment: 'center',
              margin: [0, 0, 0, 0]
          },
          org: {
              fontSize: 12,
              bold: true,
              alignment: 'center',
               margin: [0, -25, 0, 0]
          },
          address: {
              fontSize: 11,
              margin: [0, 0, 0, 5]
          },
          hh: {
              fontSize: 13,
              bold: true,
              margin: [10, 10, 25, 20]
          },
          td: {
            fontSize: 10,
            margin: [1, 1, 1, 1]
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 20]
          },
          krishi: {
            fontSize: 15,
            margin: [0, 1, 0, 25],
            alignment: 'center'
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).download('training-schedule')
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
