import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/utils/report-head-traning'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat, time12Formate } from '@/utils/fliter'
const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, data, vm, routines, committee) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
        const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
        const pdfContent = [
          { columns: reportHeadData.reportHeadColumn },
          { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
          { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
          { text: reportHeadData.address, style: 'address', alignment: 'center' },
          { text: reportTitle, style: 'hh', alignment: 'center' }
        ]
        const bodySeperate = [
          [
            { text: vm.$t('elearning_config.fiscal_year'), style: 'th', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? data.fiscal_year_bn : data.fiscal_year, style: 'td', alignment: 'left' },
            { text: vm.$t('elearning_iabm.circular_memo_no'), style: 'th', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: data.circular_memo_no, style: 'td', alignment: 'left' }
          ]
        ]
        if (Store.state.Auth.activeRoleId === 1 || Store.state.Auth.authUser.org_id === 1) {
          bodySeperate.push([
            { text: vm.$t('globalTrans.organization'), style: 'th', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? data.org_bn : data.org, style: 'td', alignment: 'left' },
            { text: vm.$t('elearning_config.training_type'), style: 'th', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? data.training_type_bn : data.training_type, style: 'td', alignment: 'left' }
          ]
          )
        }
        bodySeperate.push(
          [
            { text: vm.$t('elearning_config.training_title'), style: 'th', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? data.training_title_bn : data.training_title, style: 'td', alignment: 'left' },
            { text: vm.$t('elearning_config.batch'), style: 'th', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? data.batch_bn : data.batch, style: 'td', alignment: 'left' }
          ]
        )
        bodySeperate.push(
          [
            { text: vm.$t('globalTrans.date'), style: 'th', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: vm.date, style: 'td', alignment: 'left' },
            { text: vm.$t('elearning_tpm.training_fee'), style: 'th', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: vm.$n(vm.training_fee), style: 'td', alignment: 'left' }
          ]
        )
        pdfContent.push(
          {
            table: {
              style: 'tableData',
              headerRows: 0,
              widths: ['15%', '2%', '33%', '16%', '2%', '32%'],
              body: bodySeperate
            },
            layout: {
              hLineWidth: function (i, node) {
                return 0
              },
              vLineWidth: function (i, node) {
                return 0
              }
            }
          }
        )
        routines.map((routine, index) => {
          const rightSide = dateFormat(routine.date) + ' (' + vm.dayGetName(routine.date) + ')'
          const allRowsHead = [
            [
              { bold: true, text: vm.$t('globalTrans.day') + '-' + vm.$n(index + 1), alignment: 'left', style: 'th' },
              { bold: true, text: rightSide, alignment: 'right', style: 'th' }
            ]
          ]
          pdfContent.push({
            table: {
              headerRows: 1,
              widths: ['50%', '50%'],
              body: allRowsHead
            },
            layout: {
              hLineWidth: function (i, node) {
                return 0
              },
              vLineWidth: function (i, node) {
                return 0
              }
            }
          })
          const allRows = [
            [
              { text: vm.$t('globalTrans.time'), style: 'th', alignment: 'center', bold: true },
              { text: vm.$t('elearning_config.topics_session'), style: 'th', alignment: 'center', bold: true },
              { text: vm.$t('elearning_config.speaker_facilitator'), style: 'th', alignment: 'center', bold: true },
              { text: vm.$t('elearning_config.vanue_name'), style: 'th', alignment: 'center', bold: true }
            ]
          ]
          routine.time.forEach((sheduleData, index) => {
            sheduleData.list.forEach((info, index1) => {
              const NewLoop = []
              if (index1 === 0) {
                NewLoop.push({ text: time12Formate(info.start_time) + ' - ' + time12Formate(info.end_time), style: 'td', alignment: 'center', rowSpan: sheduleData.list.length })
              } else {
                NewLoop.push({})
              }
              if (index1 === 0) {
                NewLoop.push({ text: (i18n.locale === 'bn') ? info.topics_session_bn : info.topics_session, style: 'td', alignment: 'center', rowSpan: sheduleData.list.length })
              } else {
                NewLoop.push({})
              }
              NewLoop.push({ text: (i18n.locale === 'bn') ? info.speaker_bn : info.speaker, style: 'td', alignment: 'center' })
              if (index1 === 0) {
                NewLoop.push({ text: (i18n.locale === 'bn') ? info.venue_bn : info.venue, style: 'td', alignment: 'center', rowSpan: sheduleData.list.length })
              } else {
                NewLoop.push({})
              }
              allRows.push(NewLoop)
            })
          })
          pdfContent.push({
            table: {
              headerRows: 1,
              widths: ['25%', '25%', '25%', '25%'],
              body: allRows
            }
          })
        })
        pdfContent.push({ text: '', style: 'fertilizer' })
        pdfContent.push({ text: vm.$t('elearning_tpm.course_coordinator'), style: 'th', alignment: 'left', bold: true })
        const committeeRows = [
          [
            { text: vm.$t('globalTrans.name'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('globalTrans.designation'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('globalTrans.mobile_no') + ' / ' + vm.$t('globalTrans.email'), style: 'th', alignment: 'center', bold: true }
          ]
        ]
        committee.details.forEach((info, index) => {
          committeeRows.push([
            { text: (i18n.locale === 'bn') ? info.name_bn : info.name_en, style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? info.designation_bn : info.designation_en, style: 'td', alignment: 'center' },
            { text: (vm.$i18n.locale === 'bn' ? '০' : '0') + vm.$n(info.mobile_no, { useGrouping: false }) + '\n' + info.email, style: 'td', alignment: 'center' }
          ])
        })
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['35%', '35%', '30%'],
            body: committeeRows
          }
        })
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        styles: {
          th: {
            fontSize: (i18n === 'bn') ? 12 : 8,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n === 'bn') ? 12 : 8,
            margin: [3, 3, 3, 3]
          },
          header: {
              fontSize: 14,
              bold: true,
              alignment: 'center',
              margin: [0, 0, 0, 0]
          },
          org: {
              fontSize: 12,
              bold: true,
              alignment: 'center',
              margin: [0, -25, 0, 0]
          },
          address: {
              fontSize: 11,
              margin: [0, 0, 0, 5]
          },
          hh: {
              fontSize: 13,
              bold: true,
              margin: [10, 10, 25, 20]
          },
          fertilizer: {
            margin: [5, 0, 0, 5]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 0]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 4]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
